.react-datepicker-custom-text-br input[type="text"] {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    border-radius: 5px;
}

.react-numeric-input input[type="text"] {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    max-width: 75px;
}