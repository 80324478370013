/*
* Buttons
*/
.button {
    cursor: pointer;
    padding: 10px 30px 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }
  
  .button:hover {
    box-shadow: 3px 6px 6px rgba(0, 0, 0, 0.5);
  }
  
  .buttonSymbol {
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }
  
  .buttonSymbol:hover {
    box-shadow: 3px 6px 6px rgba(0, 0, 0, 0.5);
  }
  
  .ctaPrimary {
    background-color: #5DD077;
    color: white;
    font-weight: bold;
  }
  
  .ctaSecondary {
    background-color: #03214E;
    color: white;
  }

  /*
* Input fields
*/

.input {
    padding: 20px;
  }
  
  .input .inputLabel {
    fill: #03214E;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .input .inputField {
    border: solid 1px rgba(91, 101, 124, 0.3);
    border-radius: 5px;
    background-color: #F6F7F9;
    stroke: rgba(91, 101, 124, 0.3);
    width: 300px;
    height: 20px;
    box-sizing: content-box;
    display: flex;
    align-items:center;
    padding: 10px;
  }
  
  
  input {
    padding: 6px;
    border-radius: 5px;
    border: solid 1px rgba(91, 101, 124, 0.3);
    outline: none;
  }