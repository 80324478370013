.react-datepicker-custom-wrapper input[type="text"]{
    border: none;
    background-color: inherit;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    text-align: center;
    background-color: rgb(30 58 138);
    color: white;
}

.react-datepicker-custom-text input[type="text"] {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
}