.background {
    position: relative;
    display: inline-block;
    z-index:0;
  }
  
  .background::before {
    overflow:hidden; /* hide the overflow here not on the pseudo element */
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    /* 141% ~ sqrt(2)x100% to make sure to cover all the area after the rotation */
    background-repeat: repeat;
    opacity: 0.15;
    transition: 'background 2s linear';
  }

  .sword::before {
    background: url('./sword.svg');
  }

  .diamond:before {
    background: url('./diamond.svg');
    opacity: 0.35;
  }

  .crown:before {
    background: url('./crown.svg');
    opacity: 0.35
  }

  .money::before {
    background: url('./money.svg');
    opacity: 0.25;
  }