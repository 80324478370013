.msSignInButton {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  
  .msIcon {
    background-image: url('https://docs.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_mssymbol_19.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }