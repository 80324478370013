/* draw hierarchy lines */
/* anchor the absolutely positioned generated elements */
.user-groups-inner-foldy, .user-groups-inner-foldy > .foldy-head {
	position: relative;
}
/* trunks */
.user-groups-inner-foldy:not(:last-child):before {
	position: absolute;
	left: -0.4rem;
	top: -1rem; /* container's .gap-4 */
	bottom: 0rem;
	width: 0px;
	border-left: 1px solid #cbd5e1; /* slate-300 */
	content: '';
}
/* branches */
.user-groups-inner-foldy > .foldy-head:before {
	position: absolute;
	left: -0.4rem;
	top: -1rem; /* container's .gap-4 */
	height: 2.25rem; /* DraggableUser's (0.5 * .text-xs.line-height + .py-1) + group header's .py-2 + container's .gap-4 */
	content: '';
	width: 0.3rem;
	border-bottom-left-radius: 0.3rem;
	border-left: 1px solid #cbd5e1; /* slate-300 */
	border-bottom: 1px solid #cbd5e1; /* slate-300 */
}

/* draw lines skewering the top level, just a hint that offscreen groups exist */
/* anchor the absolutely positioned generated elements */
.user-groups-outer-foldy {
	position: relative;
}
.user-groups-outer-foldy:not(:last-child):before {
	position: absolute;
	left: 0.45rem;
	top: 1.75rem; /* DraggableUser's (0.5 * .text-xs.line-height + .py-1) + group header's .py-2 + icon radius */
	bottom: -1.75rem; /* the above + container's .gap-4 - icon diameter */
	width: 0px;
	border-left: 1px solid #e2e8f0; /* slate-200 */
	content: '';
}
