input[type="range"]
{
    padding: 0;
    border-radius: 5px;
    border: none;
    outline: none;
}


.range-wrap {
    position: relative;
    margin: 0 auto 3rem;
  }
  .range {
    width: 100%;
  }
  .bubble {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: 0s;
    opacity: 0.8;
    z-index: -1;
  }
  /* .bubble::after {
    position: absolute;
    top: -1px;
    left: 50%;
  } */


  .vl {
    border-left: 0.5px solid black;
    height: 50px;
  }