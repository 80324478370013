.foldy-icon {
	transition: transform 0.5s cubic-bezier(0.6, 2.0, 0.8, 0.9);
	transform: rotate(0deg);
	transform-origin: 0.5rem 0.5rem;
	width: 1rem;
	min-width: 1rem;
	height: 1rem;
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.foldy-open > .foldy-head > .foldy-icon {
	transform: rotate(90deg);
}
.foldy-empty > .foldy-head > .foldy-icon {
	transform: rotate(90deg);
	cursor: auto;
}
.foldy-head {
	display: flex;
	flex-flow: row;
	align-items: baseline;
	justify-content: flex-start;
}
.foldy-contents {
	display: none;
	margin-left: 2rem;
}
.foldy-open > .foldy-contents {
	display: block;
}
