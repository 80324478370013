.hover-only {
    visibility: hidden;
}

.parent:hover .hover-only {
    visibility: visible;
    opacity: 0.5
  }

  .parent .hover-only:hover {
    visibility: visible;
    opacity: 1;
  }



.hover-opacity {
  opacity: 0.6

}

.parent:hover .hover-opacity {
  opacity: 0.7
  }

.parent .hover-opacity:hover {
  visibility: visible;
  opacity: 1;
  }