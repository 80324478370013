/* imitate Filter styling */
.user-picker {
	position: relative;
	text-align: left;
	font-size: min(10px, max(8px, 1.5vw));  /* smallParagraph */
}
.user-picker-header {
	cursor: pointer;
}
.user-picker-dropdown {
	position: absolute;
	top: 100%;
	left: 0px;
	z-index: 10;
	width: 150px;
	max-width: 150px;
	background: white;
	border-radius: 0.25rem;
	border-width: 1px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px;
}
.user-picker-list {
	max-height: 16rem;
	overflow-y: auto;
	padding: 0.2em 0.5rem;
}
.user-picker-hit-target {
	cursor: pointer;
	min-width: 0px;  /* this and flex-grow are needed to `text-overflow: ellipsis` descendants */
	flex-grow: 1;
	padding: 0.2em 0em;
}
.user-picker-user, .user-picker-group {
	border-radius: 0.25rem;
	color: #000000;
	background: #c2c2c2;
	padding: 0.2em 0.5em;
}
.user-picker-selected {
	color: #ffffff;
	background: #4da56b;
}
.user-picker-hit-target:hover .user-picker-user,
.user-picker-hit-target:hover .user-picker-group {
	background-color: #d2d2d2;
}
.user-picker-hit-target:hover .user-picker-selected {
	background-color: #5db57b;
}
.user-picker-name {
	line-height: 1.4em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	user-select: none;  /* prevent drag and doubleclick from creating distracting selection */
}
.user-picker-partially-selected {
	background-image: linear-gradient(0deg, #c2c2c2 0% 40%, #4da56b 40% 100%);
	background-size: 0.5em 0.7em;
	background-repeat: repeat-y;
	background-position: 100% 0%;
}

/* top list of selected items */
.user-picker-open-header, .user-picker-selected-item {
	display: flex;
	flex-flow: row;
	gap: 0.25rem;
	align-items: center;
}
.user-picker-open-header {
	padding: 0.4em 0.5rem;
	border-bottom-width: 1px;
	flex-wrap: wrap;
}
.user-picker-selected-item {
	border-radius: 0.25rem;
	padding: 0.2em 0.5em;
	max-width: 100%;
}

/* tamper with foldy margins and sizes */
.user-picker .foldy-icon {
	width: 0.8em;
	height: 0.8em;
	min-width: 0.8em;
	min-height: 0.8em;
	max-width: 0.8em;
	max-height: 0.8em;
	transform-origin: 50% 50%;
}
.user-picker .foldy-icon > svg {
	max-width: 100%;
	max-height: 100%;
}
.user-picker .foldy-head {
	margin: 0em;
}
.user-picker .foldy-head.user-picker-user {
	margin-left: 0.8em; /* foldy-icon width */
}
.user-picker .foldy-contents {
	margin-left: 0.8em;
}

/* draw hierarchy lines */
/* anchor the absolutely positioned generated elements */
.user-picker-inner-foldy {
	position: relative;
}
/* trunks */
.user-picker-inner-foldy:not(:last-child):before {
	position: absolute;
	left: -0.4em;
	top: -0.6em; /* half box vertical gap + box bottom padding + half excess line height */
	bottom: 0em;
	width: 0px;
	border-left: 1px solid #cbd5e1; /* slate-300 */
	content: '';
}
/* branches */
.user-picker-inner-foldy > .foldy-head:before {
	position: absolute;
	left: -0.4em;
	top: -0.2em; /* half box vertical gap */
	height: 1.3em; /* gap + top padding + half line height */
	content: '';
	width: 0.4em;
	border-bottom-left-radius: 0.4em;
	border-left: 1px solid #cbd5e1; /* slate-300 */
	border-bottom: 1px solid #cbd5e1; /* slate-300 */
}
.user-picker-inner-foldy > .user-picker-user.foldy-head:before {
	width: 1.0em;
}
